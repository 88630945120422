import React, { useEffect, useState, useContext } from "react";
import Text from "../commons/Text";
import ShowAlert from "../commons/ShowAlert";
import Challenge from "../../api/Challenge";
import { AppContext } from "../context/AppContext";
import { SolutionCardLoading } from "./SolutionCard";
import WebDevelopmentImg from "../../media/images/web/business/web-development-bw.png";

const copyReadmeTemplate = () => {
  Text.copyText("readme-file");
  ShowAlert.success("El contenido ha sido copiado");
};

const SearchSolutions = () => {
  const [context, setContext] = useContext(AppContext);
  const [solutions, setSolutions] = useState([]);
  const [loading, setLoading] = useState(false);

  const getSolutions = () => {
    setLoading(true);
    Challenge.getSolutions()
      .then((data) => {
        if (data.data) {
          setSolutions(data.data);
        }
      })
      .then(() => setLoading(false));
  };

  useEffect(() => {
    getSolutions();
  }, []);
  return (
    <div>
      <div className="row big-intro mt-5 text-center">
        <div className="col-12 col-md-8 offset-md-2">
          <h1 className="big-title mb-5">Soluciones</h1>
          <p className="lead">
            Leer el código de otras personas es una excelente manera de
            aprender. Pide ayuda, comparte tu punto de vista y compara
            diferentes enfoques.
          </p>
        </div>
      </div>

      {loading && (
        <div class="row">
          <div class="col-md-4 mb-4">
            <SolutionCardLoading />
          </div>
          <div class="col-md-4 mb-4">
            <SolutionCardLoading />
          </div>
          <div class="col-md-4 mb-4">
            <SolutionCardLoading />
          </div>
        </div>
      )}
      {!loading && !solutions.length && (
        <div>Se el primero en enviar una solución :)</div>
      )}
      {!loading && solutions.length > 0 && (
        <div class="row align-items-stretch">
          {solutions.map((solution) => {
            return (
              <div class="col-md-4 mb-4">
                <div className="card">
                  <div class="media card-body">
                    <img
                      src={solution?.user.avatar}
                      className="rounded-circle mr-3"
                      width="29px"
                    />
                    <div class="media-body">
                      <strong>{solution?.user.username}</strong>
                      <p>{solution?.description.slice(0, 40)}</p>
                      <p>
                        <a
                          href={`/solutions/${solution.id}`}
                          className="text-primary"
                        >
                          Ver solución
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
export default SearchSolutions;
