import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import User from "../api/User";
import FreelanceCard, { FreelanceCardLoading } from "./user/FreelanceCard";
import ProgrammingImg from "../media/images/web/business/programming.png";
import TeachingImg from "../media/images/web/business/teaching.png";
import WorkingImg from "../media/images/web/business/laptop-at-home.png";

const Freelancers = () => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    setLoading(true);
    User.featured().then((users) => {
      if (users.status === "ok" && users.data.total) {
        setUsers(users.data.data);
      }
      setLoading(false);
    });
  }, []);

  return (
    <>
      <div className="row big-intro my-5">
        <div className="col-sm-12 col-md-8 offset-md-2 text-center">
          <h1 className="big-title mb-4">
            Mejora tu código,
            <br /> encuentra empleo
          </h1>
          <p className="lead mb-4">
            Crea tu perfil gratis, participa en desafíos y amplía tu red.
            <br />
            Ya somos <span className="font-weight-bold">más de 2500</span> devs!
          </p>

          <p>
            <Link to="/auth/register" className="btn btn-action">
              Unirme ahora
            </Link>
          </p>
        </div>
      </div>

      {loading && (
        <div className="row">
          {[1, 2].map((key) => (
            <div key={key} className="col-md-3 fade-in">
              <FreelanceCardLoading />
            </div>
          ))}
        </div>
      )}

      <div className="row">
        {users.slice(0, 4).map((user, index) => (
          <div key={user.id} className="col-md-3 fade-in">
            <FreelanceCard user={user} />
          </div>
        ))}
        <div className="col-12 mt-2 text-center">
          <Link to="/search/people" className="btn btn-secondary">
            Ver todos
          </Link>
        </div>
      </div>

      <div className="row d-flex flex-row justify-content-center align-items-center my-5 py-5">
        <div className="col-md-6 order-2 order-md-1">
          <h2 className="big-title mb-3">Crea proyectos reales</h2>
          <p className="lead mb-4">
            Aprende creando desafíos propuestos por programadores experimentados
            y aprende resolviendo{" "}
            <strong className="font-weight-bold">
              problemas del mundo real
            </strong>
            .
          </p>
          <p>
            <a href="/challenges/search" className="btn btn-primary btn-lg">
              Ver los desafíos
            </a>
          </p>
        </div>
        <div className="col-md-5 order-1 order-md-2 mb-4">
          <img
            src={ProgrammingImg}
            className="img-fluid"
            alt="Working"
            width="100%"
            height="auto"
          />
        </div>
      </div>

      <div className="row d-flex flex-row justify-content-center align-items-center my-5 py-5">
        <div className="col-md-6 order-md-2">
          <h2 className="big-title mb-3">Comunidad</h2>
          <p className="lead mb-4">
            Comparte tu código con la comunidad para pedir ayuda o ideas que
            mejoren tus habilidades.
          </p>
          <p>
            <a href="/challenges/search" className="btn btn-outline btn-lg">
              Quiero ser parte
            </a>
          </p>
        </div>
        <div className="col-md-5 order-md-1 mb-2">
          <img
            src={TeachingImg}
            className="img-fluid flip-horizontal"
            alt="Working"
            width="100%"
            height="auto"
          />
        </div>
      </div>

      <div className="row d-flex flex-row justify-content-center align-items-center my-5 py-5">
        <div className="col-md-6 order-2 order-md-1">
          <h2 className="big-title mb-3">Oportunidades laborales</h2>
          <p className="lead mb-4">
            Tenemos un área de contratación. Al crear proyectos y participar con
            la comunidad, puedes ampliar tus posibles oportunidades de empleo.
          </p>
          <p>
            <a href="/auth/register" className="btn btn-primary btn-lg">
              Crear mi cuenta gratis
            </a>
          </p>
        </div>
        <div className="col-md-5 order-2 mb-2">
          <img
            src={WorkingImg}
            className="img-fluid"
            alt="Working"
            width="100%"
            height="auto"
          />
        </div>
      </div>
    </>
  );
};

export default Freelancers;
